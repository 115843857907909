import {firebaseFirestore} from '@/utils/firebase';

export const SUPPORTED_FEATURES = Object.freeze({
    ATLASSIAN: 'atlassian',
    JIRA: 'jira',
    CONFLUENCE: 'confluence',
    BITBUCKET: 'bitbucket',
    PLANNER: 'planner',
    XERO: 'xero',
    HUBSPOT: 'hubspot',
    GCAL: 'gcal',
    GOOGLE_AUTH: 'google_auth',
    TODOS: 'todos',
    PROJECT_ESTIMATES: 'project.estimates',
    PROJECT_CHANGE_REQUESTS: 'project.change_requests',
    PROJECT_UTILS: 'project.utils',
});

const DEFAULT_FEATURES = {
    // integrations
    [SUPPORTED_FEATURES.ATLASSIAN]: false,
    [SUPPORTED_FEATURES.JIRA]: false,
    [SUPPORTED_FEATURES.CONFLUENCE]: false,
    [SUPPORTED_FEATURES.BITBUCKET]: false,
    [SUPPORTED_FEATURES.XERO]: false,
    [SUPPORTED_FEATURES.HUBSPOT]: false,
    [SUPPORTED_FEATURES.GCAL]: false,
    [SUPPORTED_FEATURES.GOOGLE_AUTH]: !!process.env.VUE_APP_GOOGLE_CLIENT_ID,
    // TOOL features
    [SUPPORTED_FEATURES.TODOS]: true,
    [SUPPORTED_FEATURES.PROJECT_ESTIMATES]: false,
    [SUPPORTED_FEATURES.PROJECT_CHANGE_REQUESTS]: false,
    [SUPPORTED_FEATURES.PROJECT_UTILS]: false,
};

const featureToggles = {
    state: {
        featureToggles: DEFAULT_FEATURES,
        //n.b. you can set this to true to test the DEFAULT_FEATURES values
        hasFetchedFeatures: false,
        bitbucketKeyExists: null,
    },

    mutations: {
        SET_FEATURES: (state, featureToggles) => {
            state.featureToggles = {...state.featureToggles, ...featureToggles};
            state.hasFetchedFeatures = true;
        },
        SET_BITBUCKET_KEY_EXISTS: (state, exists) => {
            state.bitbucketKeyExists = exists;
        },
    },

    actions: {
        fetchBitbucketKeyExists({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                try {
                    firebaseFirestore
                        .doc('system/modules/keys/bitbucket')
                        .get()
                        .then((snapshot) => {
                            commit('SET_BITBUCKET_KEY_EXISTS', snapshot.exists);
                            resolve();
                        });
                } catch (e) {
                    console.error('Error fetching Bitbucket key', e);
                    reject(e);
                }
            });
        },
        fetchFeatureToggles({commit, state}, payload) {
            if (state.hasFetchedFeatures) return;
            return new Promise((resolve, reject) => {
                try {
                    firebaseFirestore
                        .doc('system/features')
                        .get()
                        .then((snapshot) => {
                            if (snapshot.exists) {
                                const data = snapshot.data();
                                commit('SET_FEATURES', data);
                                resolve();
                            } else {
                                console.warn(
                                    'Feature toggles are not set up in DB, using default settings'
                                );
                                resolve();
                            }
                        });
                } catch (e) {
                    console.error('Error fetching feature toggles', e);
                    reject(e);
                }
            });
        },
    },

    getters: {
        features: (state) => state.featureToggles,
        /**
         * Query if a feature is currently enabled in firebase and any required criteria are met
         * @param requested the name of the feature to request. Valid values are in {@link SUPPORTED_FEATURES}
         * @returns {boolean} true if the feature is enabled
         */
        is_feature_enabled: (state, getters) => (requested) => {
            if (!requested) {
                return false;
            }

            //atlassian is a special case which depends on other features
            if (requested === SUPPORTED_FEATURES.ATLASSIAN) {
                return getters.is_atlassian_enabled;
            }

            // explicitly return false if the feature hasn't been declared
            return state.featureToggles[requested] || false;
        },
        are_all_features_enabled: (state, getters) => (features) => {
            return features.every((feature) =>
                getters.is_feature_enabled(feature)
            );
        },
        is_any_feature_enabled: (state, getters) => (features) => {
            return features.some((feature) =>
                getters.is_feature_enabled(feature)
            );
        },
        is_atlassian_enabled(state, getters) {
            return getters.is_any_feature_enabled([
                SUPPORTED_FEATURES.JIRA,
                SUPPORTED_FEATURES.CONFLUENCE,
                SUPPORTED_FEATURES.BITBUCKET,
            ]);
        },
        are_todo_screens_enabled(state, getters) {
            return getters.is_any_feature_enabled([
                SUPPORTED_FEATURES.JIRA,
                SUPPORTED_FEATURES.CONFLUENCE,
                SUPPORTED_FEATURES.TODOS,
            ]);
        },
        is_jira_enabled(state, getters) {
            return getters.is_feature_enabled(SUPPORTED_FEATURES.JIRA);
        },
        is_confluence_enabled(state, getters) {
            return getters.is_feature_enabled(SUPPORTED_FEATURES.CONFLUENCE);
        },
        is_bitbucket_authed(state, getters) {
            return !!state.bitbucketKeyExists && getters.is_bitbucket_enabled;
        },
        is_bitbucket_enabled(state, getters) {
            return getters.is_feature_enabled(SUPPORTED_FEATURES.BITBUCKET);
        },
        is_planner_enabled(state, getters) {
            return getters.is_feature_enabled(SUPPORTED_FEATURES.PLANNER);
        },
        is_xero_enabled(state, getters) {
            return getters.is_feature_enabled(SUPPORTED_FEATURES.XERO);
        },
        is_gcal_enabled(state, getters) {
            return getters.is_feature_enabled(SUPPORTED_FEATURES.GCAL);
        },
        is_hubspot_enabled(state, getters) {
            return getters.is_feature_enabled(SUPPORTED_FEATURES.HUBSPOT);
        },
    },
};

export default featureToggles;
